/**
 * This acts as an intermediary service for Nav, maintaining the component's navigation items
 * between mounts and handling changes due to nested dependencies.
 * If your MFE does not interact with Nav you can remove everything from this file.
 */
import {
  DEFAULT_LOCALES,
  init as initL10n,
  NAV_NAMESPACES,
  PSEUDO_LOCALE,
} from '@verily-src/l10n/src/l10n';
import {api} from '@verily-src/phaf-unified-api';
import {NavItem, type api as VerilyMeApi} from '@verily-src/verily-me-api';
// eslint-disable-next-line node/no-extraneous-import
import {TFunction} from 'i18next';
import {BehaviorSubject} from 'rxjs';

const items = new BehaviorSubject<NavItem[]>([]);

// Setup handlers for component to interact with Nav
export const addItemToNav = (route: NavItem) => {
  (api as typeof VerilyMeApi).nav.addItem(route);

  const oldNavItems = items.value;
  items.next([...oldNavItems, route]);
};

/**
 * Removes a route(NavItem) and updates the navItem list
 */
export const removeItemFromNav = (route: NavItem) => {
  // Remove the item from the Nav
  (api as typeof VerilyMeApi).nav.removeItem(route);

  // Update the list of controlled items so we can use it for our internal state
  const controlledItems = items.value.filter(item => item.name !== route.name);
  items.next(controlledItems);
};

/**
 * Use this function to wrap any initialization logic when setting up the initial nav items to register and show.
 */
function initRoutes(t: TFunction) {
  // Home Nav Item
  const homeNavItem = {
    name: t('nav:home'),
    path: '/me/home',
    order: 0,
    icon: 'HomeVerilyMeOutlinedIcon',
    selectedIcon: 'HomeVerilyMeFilledIcon',
    children: [],
  };

  // Discover Nav Item
  const discoverNavItem = {
    name: t('nav:discover'),
    path: '/me/discover',
    order: 1,
    icon: 'ExploreVerilyMeOutlinedIcon',
    selectedIcon: 'ExploreVerilyMeFilledIcon',
    children: [],
  };

  const initialRoutes = [homeNavItem, discoverNavItem];
  items.next(initialRoutes);
  initialRoutes.forEach(route => addItemToNav(route));
}

// Run initialization function once during import - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import#import_a_module_for_its_side_effects_only
initL10n(
  f => require(`../../l10n/locales/${f}`),
  process.env.INCLUDE_PSEUDO_LOCALE
    ? [...DEFAULT_LOCALES, PSEUDO_LOCALE]
    : DEFAULT_LOCALES,
  NAV_NAMESPACES
).then(t => initRoutes(t));
